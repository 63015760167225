<template>
  <List el="xsm">
    <Text el="h2">Readings</Text>
    <div el="input-list" style="max-width: 250px">
      <label for="inputID" el="input">
        <input
          type="text"
          name="input"
          id="inputID"
          placeholder="Search..."
          el
          v-model="modules.searchInput"
        />
      </label>
    </div>
    <div class="card-grid">
      <div
        :class="{ card: true, active: modules.selected == item.id }"
        el="list-column"
        v-for="(item, key) in filteredModules"
        :key="key"
        @click="selectModule(item.id)"
      >
        <div>
          <img :src="item.img" alt="image" loading="lazy" />
        </div>
        <div el="list">
          <h4 el>{{ item.label }}</h4>
          <p el>{{ item.description }}</p>
        </div>
      </div>
    </div>
  </List>
</template>
<script>
import { reactive } from "vue";

let filter = require("lodash/filter");
let toLower = require("lodash/toLower");
import router from "../router";
import { db } from "@/firebase";
import { collection, getDocs } from "firebase/firestore";

const modulesList = reactive({});

export default {
  components: {
    // Layout,
  },
  data() {
    return {
      window: this.$store.state.main.window,
      user: this.$store.state.main.user,
      main: this.$store.state.main.layout.main,
      section: this.$store.state.main.layout.section,
      modules: {
        searchInput: "",
        selected: null,
        library: {
          j1: {
            label: "JAVA Data Types & Variables",
            location:
              "/Library/1-Java/2 - Java Variables/Data Types_Variables.html",
            img: "",
            type: "html", //html, file, video, img
          },
          j2: {
            label: "JAVA Operator",
            location: "/Library/1-Java/3 - Java Operators/Java_Operator.html",
            img: "",
            type: "html", //html, file, video, img
          },
          j3: {
            label: "JAVA Control Flows",
            location:
              "/Library/1-Java/4 - Java Control Flows/Java Control Flows.html",
            img: "",
            type: "html", //html, file, video, img
          },
          j4: {
            label: "JAVA Loops",
            location: "/Library/1-Java/5 - Java loops/Java Loops.html",
            img: "",
            type: "html", //html, file, video, img
          },
          j5: {
            label: "JAVA Arrays",
            location: "/Library/1-Java/6 - Java Arrays/Java Arrays.html",
            img: "",
            type: "html", //html, file, video, img
          },
          j6: {
            label: "JAVA Methods",
            location: "/Library/1-Java/7 - Java Methods/Java Method.html",
            img: "",
            type: "html", //html, file, video, img
          },
          j7: {
            label: "JAVA Class & Object",
            location:
              "/Library/1-Java/8 - Java Class & Object/Class & Object.html",
            img: "",
            type: "html", //html, file, video, img
          },
          j8: {
            label: "Java Encapsulation & Abstraction",
            location:
              "/Library/1-Java/9 - Java Encapsulation & Abstraction/Java Encapsulation & Abstraction.html",
            img: "",
            type: "html", //html, file, video, img
          },
          j9: {
            label: "Java Inheritance",
            location:
              "/Library/1-Java/10 - Java Inheritance/Java Inheritance.html",
            img: "",
            type: "html", //html, file, video, img
          },
          j10: {
            label: "Java Polymorphism",
            location:
              "/Library/1-Java/11-Interface & Polymorphisim/Polymorphism.html",
            img: "",
            type: "html", //html, file, video, img
          },
          m1: {
            label: "Maven Introduction",
            location: "/Library/2-Maven/Introduction to Maven.html",
            img: "",
            type: "html", //html, file, video, img
          },
          t1: {
            label: "TestNG - Part 1",
            location:
              "/Library/3-Testing Framework/TestNG/TestNG Testing Framework - Part I.html",
            img: "",
            type: "html", //html, file, video, img
          },
        },
      },
    };
  },
  methods: {
    selectModule(id) {
      this.modules.selected = id;
      router.push("/dashboard/modules/" + id);
      this.section.collapse = false;
    },
    async getModules() {
      const querySnapshot = await getDocs(collection(db, "modules"));
      querySnapshot.forEach((doc) => {
        let data = doc.data();
        data.id = doc.id;
        modulesList[doc.id] = data;
      });
      if (this.$route.params.id != null) {
        this.modules.selected = this.$route.params.id;
      }
    },
  },
  computed: {
    selectedModule() {
      if (this.modules.selected != null) {
        //console.log(this.layout.main.modules.list[this.layout.main.modules.selected]);
        return this.modules.list[this.modules.selected];
      } else {
        return "";
      }
    },
    filteredModules() {
      var input = this.modules.searchInput;
      return filter(modulesList, function (item) {
        return (
          toLower(item.label).search(toLower(input)) >= 0 && item.show == true
        );
      });
    },
  },
  mounted() {
    this.getModules();
    if (!this.$route.params.id) {
      this.section.collapse = true;
    }
  },
};
</script>
<style lang="scss" scoped>
.card-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 16px;
}
.card {
  padding: 16px;
  border-radius: 5px;
  --bg-color: #fff;
  box-shadow: 0 0 0 1px transparent inset, 0 3px 8px hsla(0, 0%, 0%, 0.05);
  --gap: 16px;
  transition: box-shadow 0.2s;
  user-select: none;
  cursor: pointer;
}
.card:hover,
.card.active {
  box-shadow: 0 0 0 1px var(--color-mist) inset, 0 3px 8px hsla(0, 0%, 0%, 0.05);
}
.card.active {
  --bg-color: var(--color-sky-3);
}
.card img {
  height: 65px;
  width: 65px;
  object-fit: cover;
  object-position: center;
  border-radius: 8px;
  background-color: #fff;
}
</style>
